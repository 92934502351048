// Import flag icon config
@use "/node_modules/flag-icons/css/flag-icons.css";

/* Tailwindcss import */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Montserrat";
  font-weight: 700; /* Bold */
  src: url("../assets/fonts/montserrat/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 600; /* Semi-Bold */
  src: url("../assets/fonts/montserrat/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 500; /* Medium */
  src: url("../assets/fonts/montserrat/Montserrat-Medium.ttf") format("truetype");
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
}

html {
  --ion-dynamic-font: "Montserrat" !important;
  --ion-font-family: "Montserrat" !important;
  --ion-backdrop-color: #012a36 !important;
}

:root {
  --brand-0: #e5eff2;
  --brand-50: #d2e4ea;
  --brand-100: #b7d3dc;
  --brand-150: #a0bec7;
  --brand-300: #5e838e;
  --brand-400: #335b66;
  --brand-500: #012a36;

  --neutral-100: #fefdfd;

  --radius-200: 12px;

  --ion-background-color: #fefdfd;
}

ion-toolbar {
  margin-top: 0 !important;
}

/* Mobile */
ion-content {
  --padding-bottom: 20px;
  --padding-end: 20px;
  --padding-start: 20px;
  --padding-top: 20px;
}

/* Tablet */
@media (min-width: 640px) {
  ion-content {
    --padding-bottom: 40px;
    --padding-end: 40px;
    --padding-start: 40px;
    --padding-top: 40px;
  }

  ion-modal ion-content {
    --padding-bottom: 20px;
    --padding-end: 20px;
    --padding-start: 20px;
    --padding-top: 20px;
  }
}

/* Desktop */
@media (min-width: 1280px) {
  ion-content {
    --padding-bottom: 90px;
    --padding-end: 60px;
    --padding-start: 60px;
    --padding-top: 90px;
  }

  ion-modal ion-content {
    --padding-bottom: 24px;
    --padding-end: 24px;
    --padding-start: 24px;
    --padding-top: 24px;
  }
}

/* Large Desktop */
@media (min-width: 1600px) {
  ion-content {
    --padding-bottom: 90px;
    --padding-end: 120px;
    --padding-start: 120px;
    --padding-top: 90px;
  }
}

ion-modal {
  --backdrop-opacity: 0.32;

  // Right Side Drawer
  &.right-side-drawer {
    --height: 100%;
    --width: 40%;
    --border-radius: 12px 0 0 12px;
    --min-width: 640px;

    &::part(content) {
      position: fixed;
      top: 0;
      right: 0;
    }
  }

  // Action Sheet
  &.action-sheet-modal {
    --height: auto;
    --width: 100vw;
    --border-radius: 12px 12px 0 0;

    &::part(content) {
      position: absolute;
      bottom: 0;
      max-height: 100%;
    }
  }

  // Datetime-input modal (mobile only)
  &.datetime-popup {
    --height: auto;
    --width: 100vw;
    --border-radius: 12px 12px 0 0;

    &::part(content) {
      position: absolute;
      bottom: 0;
      max-height: 100%;
    }
  }

  // Mobile bottom sheet modal
  &.bottom-modal {
    --border-radius: 12px 12px 0 0;
    --height: calc(100% - (max(35px, env(safe-area-inset-top))));
    --width: 100%;

    &::part(content) {
      position: absolute;
      bottom: 0;
      max-height: 100%;
    }
  }

  // Edit Photo mobile bottom sheet modal
  &.edit-photo {
    --border-radius: 12px;
    --height: 33vh;
  }

  &.add-profession {
    --border-radius: 12px;
    --height: 80vh;
    --width: 90%;
    @screen tablet {
      --width: 78%;
    }
    @screen desktop {
      --width: 45%;
    }
  }

  // Auto-height modal
  &.dynamic-height-modal {
    --border-radius: var(--radius-200);
    --height: unset;
    --max-height: 95vh;

    --width: 90%;
    @screen tablet {
      --width: 78%;
    }
    @screen desktop {
      --width: 45%;
    }

    &::part(content) {
      overflow: auto;
    }

    .ion-page {
      overflow: unset;
      ion-header {
        position: sticky;
        top: 0;
      }
      ion-footer {
        position: sticky;
        bottom: 0;
      }
    }
  }

  &.no-scroll-dynamic-height-modal {
    &::part(content) {
      overflow: hidden !important;
    }
  }

  &.modal-default.show-modal:last-of-type {
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  }
}

ion-popover {
  &.datetime-popup {
    --width: auto;
    --background: transparent;
    --box-shadow: none;
  }
}

@layer components {
  .ngx-toastr {
    margin-top: env(safe-area-inset-top) !important;
    background-color: #ffffff !important;
    border-radius: 12px !important;
    border-left: 12px solid;
    background-position: 12px 15.5px !important;
    width: 45vh !important;
    @screen tablet {
      width: 412px !important;
    }
    &.toast-success {
      background-image: url(../assets/icon/toast/success-icon.svg);
      @apply border-success-500;
    }

    &.toast-error {
      background-image: url(../assets/icon/toast/error-icon.svg);
      @apply border-error-500;
    }

    &.toast-warning {
      background-image: url(../assets/icon/toast/warn-icon.svg);
      @apply border-warning-500;
    }

    &.toast-info {
      background-image: url(../assets/icon/toast/info-icon.svg);
      @apply border-infos-500;
    }
  }
}

ion-datetime::part(calendar-day) {
  color: #011920;
}

ion-datetime::part(calendar-day):focus {
  background: transparent;
  box-shadow: none;
}

ion-datetime {
  --background: #fefdfd;
  --title-color: #012a36;
  --wheel-highlight-background: #d2e4ea;
  --wheel-fade-background-rgb: 252, 251, 250; // Format is good see : https://ionicframework.com/docs/api/datetime#css-custom-properties
}

ion-datetime::part(calendar-day today) {
  color: #012a36 !important;
  border: 1px solid #012a36;
}

ion-datetime::part(calendar-day active) {
  color: white !important;
  background: #012a36 !important;
  border: 1px solid #012a36 !important;
}

ion-datetime::part(wheel-item) {
  color: #011920 !important;
}

ion-datetime::part(wheel-item active) {
  color: #012a36 !important;
}

ion-datetime::part(month-year-button) {
  color: #012a36 !important;
  @apply font-montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.calendar-action-buttons ion-button {
  color: #012a36 !important;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

// REMOVE ARROW ON INPUT
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@layer utilities {
  //* Remove Scrollbar but keep scroll
  .scrollbar-hide {
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.action-sheet-popover {
  --width: auto !important;
  --height: auto !important;
  --max-width: 90vw;
  --max-height: 80vh;
  overflow: visible;
}
