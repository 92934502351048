/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */
@use "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@use "@ionic/angular/css/normalize.css";
@use "@ionic/angular/css/structure.css";
@use "@ionic/angular/css/typography.css";
@use "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@use "@ionic/angular/css/padding.css";
@use "@ionic/angular/css/float-elements.css";
@use "@ionic/angular/css/text-alignment.css";
@use "@ionic/angular/css/text-transformation.css";
@use "@ionic/angular/css/flex-utils.css";

/* typography import */
@use "node_modules/@appines/archimed/src/styles/archimed-typography";

/* archimed project import */
@use "node_modules/@appines/archimed/src/styles/archimed";




//* Dragula
/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
  //* Hide element with this class in the drag preview
  .delete-button-icon {
    display: none;
  }
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
